import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../store/slices/login/loginSlice";
import roleReducer from "../store/slices/roles/roleSlice";
import valuexParameterReducer from "../store/slices/valuexParameter/valuexParameterSlice";
import customerReducer from "../store/slices/customers/customersSlice";
import deviceReducer from "../store/slices/devices/devicesSlice";
import userMessageReducer from "../store/slices/userMessage/userMessageSlice";
import customerConfigReducer from "../store/slices/customerConfig/customerConfigSlice";
import customerInfraestructureReducer from "../store/slices/customerInfraestructure/customerInfraestructureSlice";
import customFieldReducer from "../store/slices/customField/customFieldSlice";
import coordinatesReducer from "../store/slices/coordinates/coordinatesSlice";
import variableReducer from "../store/slices/variable/variableSlice";
import boardsReducer from "../store/slices/boards/boardsSlice";
import refreshReducer from "../store/slices/refresh/refreshSlice";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import customerDevicesReducer from "../store/slices/customerDevices/customersDevicesSlice"
import mqttClientReducer from "./slices/mqttClient/mqttClientSlice"
import LanguageReducer from "./slices/language/languageSlice"
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "login",
    "roles",
    "customers",
    "devices",
    "customerConfig",
    "valuexParameter",
    "customField",
    "customerInfraestructure",
    "variable",
    "customerDevicesReducer",
    "language"
  ],
};

const rootReducer = combineReducers({
  login: loginReducer,
  roles: roleReducer,
  customers: customerReducer,
  devices: deviceReducer,
  userMessage: userMessageReducer,
  customerConfig: customerConfigReducer,
  valuexParameter: valuexParameterReducer,
  customField: customFieldReducer,
  customerInfraestructure: customerInfraestructureReducer,
  coordinates: coordinatesReducer,
  variable: variableReducer,
  boards:boardsReducer,
  refresh: refreshReducer,
  customersDevices: customerDevicesReducer,
  mqqtClient:mqttClientReducer,
  language:LanguageReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
