import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  variable: {},
};

export const variableSlice = createSlice({
  name: "variableSlice",
  initialState,
  reducers: {
    startLoginLoading: (state) => {
      state.isLoading = true;
    },
    setIsError: (state, payload) => {
      state.isLoading = false;
      state.isError = true;
      state.messageResult = payload.detail;
    },
    setVariable: (state, payload) => {
      state.variable = payload.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setVariableInitialState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoginLoading,
  setIsError,
  setVariable,
  setVariableInitialState,
} = variableSlice.actions;

export default variableSlice.reducer;
