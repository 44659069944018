import { createSlice } from "@reduxjs/toolkit";
import espanol from './espanol.json'
// Obtiene el idioma preferido del navegador del usuario
const userLanguage = navigator.language || navigator.userLanguage;



const initialState = {
  isLoading: false,
  data: espanol,
  languageSelect: userLanguage.startsWith("es")?'espanol':'',
};

export const languageSlice = createSlice({
  name:'spanish',
  initialState,
  reducers:{
    toggleLoading:(state)=>{
      state.isLoading=!state.isLoading
    },
    setData:(state,{payload})=>{
      state.languageSelect=payload.languageSelect
      state.data=payload.data
    }
  }

})

export const selectLanguageData = state => state.languageSlice.data;

// export const selectLanguageData = state =>languageSlice.data

// Exporta las actions generadas automáticamente por Redux Toolkit
export const {toggleLoading,setData} = languageSlice.actions;

export default languageSlice.reducer;
