import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    isError: false,
    refresh: false,
}

export const refreshSlice = createSlice({
    name: 'refreshSlice',
    initialState,
    reducers: {
        startRefreshLoading:(state)=>{
            state.isLoading = true;
        },
        setIsError:(state, payload)=>{
            state.isLoading = false;
            state.isError = true;
            state.messageResult = payload.detail;
        },
        setRefresh:(state, payload)=>{
            state.refresh = payload.payload;
            state.isLoading = false;
            state.isError = false;
        },
        setRefreshInitialState:(state)=>{
          state.isLoading = false;
          state.isError = false;
          state.refresh = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    startRefreshLoading,
    setIsError,
    setRefresh,
    setRefreshInitialState,
} = refreshSlice.actions

export default refreshSlice.reducer

