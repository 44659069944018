/*
 * Loading.js
 * Descripción:La función "Cargar" muestra un control giratorio de carga con contenido de texto opcional basado en
 * los accesorios "abrir" y "cargar".
 * Autor: Picolab S.A
 * Fecha de Creación: Noviembre 2023
 */


import React from "react";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


/**
 * La función "Cargar" muestra un control giratorio de carga con contenido de texto opcional basado en
 * los accesorios "abrir" y "cargar".
 * @param {bool} open - Estado del componente - true or false
 * @param {string} loading - texto de estado que cargará en el componente
 * @returns Se está devolviendo el componente "Cargando". Representa un contenedor de carga con un
 * fondo y un indicador de progreso circular. Si se proporciona el accesorio `loading`, también muestra
 * un mensaje con el texto de carga.
 */
function Loading ({open, loading}) {
    
    return(
        <div className="loading-container">
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)' 
                }}
                open={open}
            >
                <CircularProgress
                    sx={{
                        color: 'var(--main-color)'
                    }}
                />
                {
                    loading !== undefined &&
                    <p style={{padding: '1rem', color: '#fff'}}>{loading}</p>
                }
            </Backdrop>
        </div>
    );
}

export { Loading };