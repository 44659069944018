/*
 * App.js
 * Descripción: Componente principal de la aplicación.
 * Autor: Picolab SA
 * Fecha de Creación:2023
 */

import React from "react";
import { AppUI } from "./AppUI";

import { Provider } from "react-redux";
import { store} from "./store/store";
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { persistStore } from "redux-persist";
import "./App.css";

/**
 * Componente principal de la aplicación.
 * @returns {JSX.Element} - Elemento JSX que representa la aplicación.
 */
function App() {
  const persistor = persistStore(store)

  return (
      <PersistGate persistor={persistor}>
        <Provider store={store}>
        <AppUI />
      </Provider>
    </PersistGate>
  );
}



export default App;
