import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  customerInfraestructure: [],
}

export const customerInfraestructureSlice = createSlice({
  name: 'customerInfraestructureSlice',
  initialState,
  reducers: {
    startInfraestructureLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
    setCustomerInfraestructure:(state, payload)=>{
        state.customerInfraestructure = payload.payload;
        state.isLoading = false;
        state.isError = false;
    },
    setCustomerInfraestructureInitialState:(state)=>{
      state.isLoading = false;
      state.isError = false;
      state.customerInfraestructure = [];
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
    startInfraestructureLoading,
    setIsError,
    setCustomerInfraestructure,
    setCustomerInfraestructureInitialState,
} = customerInfraestructureSlice.actions

export default customerInfraestructureSlice.reducer