/*
 * UserMessage.js
 * Descripción: muestra un Snackbar con un mensaje de alerta basado en los valores de
 * estado de mensaje, isOpen y tipo.
 * Autor: Picolab S.A
 * Fecha de Creación: Diciembre 2023
 */

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setIsOpen } from "../../store/slices/userMessage/userMessageSlice";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
/**
 * El componente UserMessage muestra un Snackbar con un mensaje de alerta basado en los valores de
 * estado de mensaje, isOpen y tipo.
 * @returns {JSX.Element} - Se está devolviendo el componente `UserMessage`. Es un componente funcional que representa
 * un componente Snackbar desde una biblioteca de UI (como Material-UI) en función de los valores de
 * estado `isOpen`, `message` y `type` obtenidos de la tienda Redux usando el Hook `useSelector`.
 * Snackbar muestra un componente de alerta con el contenido del mensaje y la gravedad según el valor
 * "tipo". El
 */
export const UserMessage = () => {
    const {message, isOpen, type} = useSelector((state)=> state.userMessage);
    const dispatch = useDispatch()
    const handleClose= ()=>{
        dispatch(setIsOpen())
    }
    return (
      <Snackbar
      open={isOpen}
      autoHideDuration={type === 'warning' ? 5000 : 2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
