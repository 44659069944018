/*
 * MyRoutes.js
 * Descripción: Configuración de rutas para la aplicación utilizando React Router.
 * Incluye rutas públicas y privadas, así como carga diferida (lazy loading) de componentes.
 * Autor: Sebastian Contreras Bello
 * Fecha de Creación: --
 */

import { lazy, Suspense, React } from "react";
import { Route, Routes, } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
  LOGIN_ROUTE, 
  NOTFOUND_ROUTE,
  BOARD_INFO_ROUTE,BOARD_USERCUSTOMER_VIEWER_ROUTE,
  BOARD_USERCUSTOMER_ADMIN_ROUTE,
  PASSWORD_RECOVERY_ROUTE, 
  INIT_ROUTE, CLIENTS_ROUTE, 
  DEVICE_ROUTE, ADMIN_ROUTE, 
  CLIENT_NEW_ROUTE, 
  DEVICE_NEW_ROUTE,
  VARIABLE_EDIT_ROUTE, 
  DEVICE_EDIT_ROUTE, 
  USER_CONFIRMED_ROUTE, 
  USER_INFO_ROUTE, 
  INFRAESTRUCTURE_ROUTE, 
  DEVICE_INFO_ROUTE,
  BOARD_VIEW_ROUTE,
  SUPPORT_ROUTE,
  DEVICE_CUSTOMER_EDIT_ROUTE, 
  ACTIVE_PASS_ROUTE
} from '../utils/constants/routes';
import { Loading } from "../components/Loading/Loading";


//Hooks

const NotFound = lazy(() => import("../pages/NotFound"));
const Clients = lazy(() => import("../features/Clients"));
const Device = lazy(() => import("../features/Device"));
const Home = lazy(() => import("../features/Home"));
const Login = lazy(() => import("../features/Login"));
const Management = lazy(() => import("../features/Management"));
const NewClient = lazy(() => import("../features/NewClient"));
const NewDevice = lazy(() => import("../features/NewDevice"));
const EditDevice = lazy(() => import("../features/EditDevice"));
const EditDeviceCustomer = lazy(() => import("../features/ClientInfo/Components/EditDeviceCustomerForm"));
const EditVariableForm = lazy(() => import("../features/Device/components/EditVariableForm"));
// const ActivePassword = lazy (()=>import("../features/ActivePassword"))
const ClientInfo = lazy(() => import("../features/ClientInfo"));
const ConfirmUser = lazy(() => import("../features/ConfirmUser"));
const Infraestructure = lazy(() => import("../features/Infraestructure"));
const DeviceInfo = lazy(() => import("../features/DeviceInfo"));
const ForgotPassword = lazy(() => import("../features/ForgotPassword"));
const RouterContainer = lazy(() => import("../components/Hoc/RouterContainer"));
const BoardInfo = lazy(()=> import("../features/BoardInfo"))
const BoardView = lazy(()=> import("../features/BoardViewer"))
const BoardAdminViewer = lazy(()=>import("../features/BoardAdminViewer"))
const BoardUserViewer = lazy(() => import("../features/BoardUserViewer"))
const Support = lazy(() => import("../features/Support"))
const VariablePersonalizated = lazy(() => import("../features/VariablePersonalizedInfo"))

/**
 * @function MyRoutes
 * @description Componente que define las rutas de la aplicación utilizando React Router.
 * Incluye rutas públicas y privadas, así como la carga diferida de componentes utilizando lazy loading.
 * @returns {JSX.Element} Componente de React que representa las rutas de la aplicación.
 */
function MyRoutes() {

  const {user} = useSelector((state) => state.login);

  return (
    <>
      <Routes>
        {/**
         * ! PUBLIC
         */}
        <Route path={LOGIN_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Login /></Suspense>}key={LOGIN_ROUTE} />
        <Route path={NOTFOUND_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><NotFound /></Suspense>} key={NOTFOUND_ROUTE}/>
        <Route path={PASSWORD_RECOVERY_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><ForgotPassword /></Suspense>} key={PASSWORD_RECOVERY_ROUTE}/>
        <Route path={USER_CONFIRMED_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><ConfirmUser /></Suspense>}key={USER_CONFIRMED_ROUTE} />
        {/* <Route path={ACTIVE_PASS_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><ActivePassword /></Suspense>}key={ACTIVE_PASS_ROUTE} /> */}
        {/**
         * ! PRIVATE
         */}
        <Route element={<RouterContainer isAllowed={!!user}/>} key={"hoc"}>
          <Route path={INIT_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Home /></Suspense>} key={INIT_ROUTE} />
          <Route path={SUPPORT_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Support/></Suspense>} key={SUPPORT_ROUTE}/>
          <Route path={BOARD_INFO_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><BoardInfo /></Suspense>}key={BOARD_INFO_ROUTE} />
          <Route path={BOARD_VIEW_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><BoardView /></Suspense>} key={BOARD_VIEW_ROUTE} />
        </Route>
        {
          /**A continuacion separamos las rutas dependiendo el acceso y control del rol de cada usurio
           * ! No se repiten rutas, si hay roles que tengan algo en comun, se añade a la condicion
           * *ACCESO UNICAMENTE ADMIN
           */
        }
        <Route element={<RouterContainer isAllowed={user.IDRol === 1}/>} key={"hoc"}>
          <Route path={CLIENTS_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Clients /></Suspense>}key={CLIENTS_ROUTE}/>
          <Route path={DEVICE_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Device /></Suspense>}key={DEVICE_ROUTE} />
          <Route path={ADMIN_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Management /></Suspense>}key={ADMIN_ROUTE} />
          <Route path={CLIENT_NEW_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><NewClient /></Suspense>}key={CLIENT_NEW_ROUTE} />
          <Route path={DEVICE_NEW_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><NewDevice /></Suspense>}key={DEVICE_NEW_ROUTE} />
          <Route path={DEVICE_EDIT_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><EditDevice /></Suspense>}key={DEVICE_EDIT_ROUTE} />
          <Route path={VARIABLE_EDIT_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><EditVariableForm /></Suspense>}key={VARIABLE_EDIT_ROUTE} />
        </Route>
        {
          /**
           * *ACCESO PARA ADMIN Y CUSTOMER_ADMIN
           */
        }
        <Route element={<RouterContainer isAllowed={user.IDRol === 1 || user.IDRol === 2}/>} key={"hoc"}>
          <Route path={USER_INFO_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><ClientInfo /></Suspense>} key={USER_INFO_ROUTE}/>
          <Route path={DEVICE_CUSTOMER_EDIT_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><EditDeviceCustomer /></Suspense>}key={DEVICE_CUSTOMER_EDIT_ROUTE} />
          <Route path={DEVICE_INFO_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><DeviceInfo /></Suspense>} key={DEVICE_INFO_ROUTE}/>
          <Route path={"VariablePersonalizada"} element={<Suspense fallback={<Loading open={true} loading={true} />}><VariablePersonalizated /></Suspense>} key={"VariablePersonalizada"}/>
          </Route>
        {
          /**
           * *ACCESO UNICAMENTE PARA CUSTOMER ADMIN
           */
        }
        <Route element={<RouterContainer isAllowed={user.IDRol === 2}/>} key={"hoc"}>
          <Route path={INFRAESTRUCTURE_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><Infraestructure /></Suspense>} key={INFRAESTRUCTURE_ROUTE}/>
          {/* BoardViewers */}
          <Route path={BOARD_USERCUSTOMER_ADMIN_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><BoardAdminViewer/></Suspense>} key={BOARD_USERCUSTOMER_ADMIN_ROUTE}/>
        </Route>
        {
          /**
           * *ACCESO UNICAMENTE PARA CUSTOMER VIEWER
           */
          <Route element={<RouterContainer isAllowed={user.IDRol === 3}/>} key={"hoc"}>
            {/* BoardViewers */}
            <Route path={BOARD_USERCUSTOMER_VIEWER_ROUTE} element={<Suspense fallback={<Loading open={true} loading={true} />}><BoardAdminViewer/></Suspense>} key={BOARD_USERCUSTOMER_VIEWER_ROUTE}/>
          </Route>
        }
      </Routes>
    </>
  );
}

export { MyRoutes };
