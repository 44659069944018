import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  boards: [],
};
export const boardsSlice = createSlice({
  name: "boardsSlice",
  initialState,
  reducers: {
    startBoarLoading: (state) => {
      state.isLoading = true;
    },
    setIsError: (state, payload) => {
      state.isLoading = false;
      state.isError = true;
      state.messageResult = payload.detail;
    },
    setBoard: (state, payload) => {
      state.boards = payload.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setCBoarInitialState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.boards = [];
    },
    addBoarLocal: (state, { payload }) => {
      const boardAux = [...state.boards];
      boardAux.push(payload);
      state.boards = boardAux;
      state.isLoading = false;
    },
    updateBoardLocal: (state, { payload }) => {
      state.boards = state.boards.map((board) => {
        if (board.ID === payload.ID) {
          return payload;
        }
        return board;
      });
      state.isLoading = false;
    },
    deleteBoardLocal: (state, { payload }) => {
      state.boards = state.boards.filter((board) =>  board.ID !== payload.ID  );
      state.isLoading = false;
    },
  },
});
export const {
  startBoarLoading,
  setIsError,
  setBoard,
  setCBoarInitialState,
  addBoarLocal,
  updateBoardLocal,
  deleteBoardLocal
} = boardsSlice.actions;

export default boardsSlice.reducer;
