import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  islogged: false,
  isLoading: false,
  isError: false,
  messageResult:"",
  auth:{},
  user:{},
  languageData:null
}

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    startLoginLoading:(state)=>{
        state.isLoading = true;
    },
    startLoginLoadingEnd:(state)=>{
      state.isLoading = false;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
    setTokenAccess:(state, payload)=>{
        state.auth = payload.payload;
        state.isError = false;
    },
    setUserInfo:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.user = payload.payload;
        state.islogged=true;
    },
    setLogOut:(state)=>{
      state.islogged=false;
      state.user={};
      state.auth={};
    }
  }
})

// Action creators are generated for each case reducer function
export const { startLoginLoading, startLoginLoadingEnd,setIsError,setTokenAccess,setUserInfo, setLogOut,setLoginLoading } = loginSlice.actions



export default loginSlice.reducer