/*
 * index.js
 * Descripción: Punto de entrada de la aplicación.
 * Autor: Picolab SA
 * Fecha de Creación:2023
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './Index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

