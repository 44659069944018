/**
 * ! APP ROUTES
 */
export const LOGIN_ROUTE = "/";
export const NOTFOUND_ROUTE = "*";
export const PASSWORD_RECOVERY_ROUTE = "/RecuperarContrasena";
export const INIT_ROUTE = "/Inicio";
export const CLIENTS_ROUTE = "/Clientes";
export const DEVICE_ROUTE = "/Dispositivos";
export const ADMIN_ROUTE = "/Administracion";
export const CLIENT_NEW_ROUTE = "/NuevoCliente";
export const DEVICE_NEW_ROUTE = "/NuevoDispositivo";
export const DEVICE_EDIT_ROUTE = "/EditarDispositivo";
export const DEVICE_CUSTOMER_EDIT_ROUTE = "/EditarDispositivo_Cliente";
export const VARIABLE_EDIT_ROUTE = "/EditarVariable";
export const USER_CONFIRMED_ROUTE = "/ConfirmarUsuario";
export const USER_INFO_ROUTE = "/InfoClientes";
export const INFRAESTRUCTURE_ROUTE = "/Infraestructura";
export const DEVICE_INFO_ROUTE = "/InfoDispositivo";
export const BOARD_INFO_ROUTE = "/InfoTablero";
export const BOARD_VIEW_ROUTE = "/Tablero";
export const BOARD_USERCUSTOMER_VIEWER_ROUTE = "/UserViewerTableros";
export const BOARD_USERCUSTOMER_ADMIN_ROUTE = "/UserAdminTableros";
export const SUPPORT_ROUTE = "/Soporte";
export const ACTIVE_PASS_ROUTE = "/ActivarContrasena";
