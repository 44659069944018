import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  messageResult:"",
  valuexParameters:{},
  parameterType:{},
  countries:{}
}

export const valuexParameterSlice = createSlice({
  name: 'valuexParameterSlice',
  initialState,
  reducers: {
    startValuexParameterLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
   
    setValuexParameters:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.valuexParameters = payload.payload;
    },
    setParameterType:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.parameterType = payload.payload;
    },
    setValuexParametersReset:(state)=>{
      state.valuexParameters={};
      state.parameterType={};
    },
    setCountries:(state,payload)=>{
      state.isLoading = false;
      state.isError = false;
      state.countries = payload.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { startValuexParameterLoading,setIsError,setValuexParameters,setValuexParametersReset, setParameterType, setCountries } = valuexParameterSlice.actions

export default valuexParameterSlice.reducer