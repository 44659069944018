import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customerNDOC:'',
  customerDevicesSelected:[]
}

export const customerDevicesSlice = createSlice({
  name: 'customerDevicesSlice',
  initialState,
  reducers: {
    setCustomerDevicesSelected:(state, payload)=>{
        state.customerNDOC = payload.payload.customerNDOC
        state.customerDevicesSelected = payload.payload.customerDevicesSelected;
    }, 
  }
})

// Action creators are generated for each case reducer function
export const { setCustomerDevicesSelected } = customerDevicesSlice.actions

export default customerDevicesSlice.reducer