import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  message: "",
  type: "success"
}

export const userMessageSlice = createSlice({
  name: 'userMessageSlice',
  initialState,
  reducers: {
   setMessage:(state, {payload})=>{
        state.isOpen = true;
        state.message = payload.message;
        state.type = payload.type;
   },
   setIsOpen:(state)=>{
        state.isOpen= false;
   }  
  }
})

// Action creators are generated for each case reducer function
export const { setMessage ,setIsOpen } = userMessageSlice.actions

export default userMessageSlice.reducer