import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  customerAdmin:{},
  customerSelected:{}
}

export const customerSlice = createSlice({
  name: 'customerSlice',
  initialState,
  reducers: {
    startLoginLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
    setCustomerAdmin:(state, payload)=>{
        state.customerAdmin = payload.payload;
        state.isLoading = false;
        state.isError = false;
    },
    setCustomerSelected:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.customerSelected = payload.payload;
    },
    setCustomerInitialState:(state)=>{
      state = initialState
    }
    
  }
})

// Action creators are generated for each case reducer function
export const { startLoginLoading,setIsError,setCustomerAdmin,setCustomerInitialState,setCustomerSelected } = customerSlice.actions

export default customerSlice.reducer