/*
 * AppUI.js
 * Descripción: Componente de interfaz principal de la aplicación.
 * Autor: Picolab SA
 * Fecha de Creación:2023
 */

import React from "react";
import { MyRoutes } from "./routers/routes";
import { BrowserRouter } from "react-router-dom";
import { UserMessage } from "./components/UserMessage/UserMessage";

/**
 * Componente de interfaz principal de la aplicación.
 * @returns {JSX.Element} - Elemento JSX que representa la interfaz principal de la aplicación.
 */
function AppUI() {
  return (
    <BrowserRouter>
        <MyRoutes />
        <UserMessage/>
    </BrowserRouter>
  );
}

export { AppUI };
