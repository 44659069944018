import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  coordinatePolygon:[],
  coordinateMarker:[],
}

export const coordinatesSlice = createSlice({
  name: 'coordinatesSlice',
  initialState,
  reducers: {
    startCoordinatesLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
    setCoordinatePolygon:(state, payload)=>{
        state.coordinatePolygon = payload.payload;
        state.isLoading = false;
        state.isError = false;
    },
    setCoordinateMarker:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.coordinateMarker = payload.payload;
    },
    setCoordinatesInitialState:(state)=>{
      state.isLoading = false;
      state.isError = false;
      state.coordinatePolygon = [];
      state.coordinateMarker = [];
    }
    
  }
})

// Action creators are generated for each case reducer function
export const { 
    startCoordinatesLoading,
    setIsError,
    setCoordinatePolygon,
    setCoordinatesInitialState,
    setCoordinateMarker 
} = coordinatesSlice.actions

export default coordinatesSlice.reducer