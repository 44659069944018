import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isError: false,
  devices: [],
  devicesCustomerSelected: [],
  deviceType: "",
};

export const devicesSlice = createSlice({
  name: "devicesSlice",
  initialState,
  reducers: {
    startLoginLoading: (state) => {
      state.isLoading = true;
    },
    setIsError: (state, payload) => {
      state.isLoading = false;
      state.isError = true;
      state.messageResult = payload.detail;
    },
    setDevices: (state, payload) => {
      state.devices = payload.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setDevicesCustomerSelected: (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.devicesCustomerSelected = payload.payload;
    },
    setDeviceType: (state, payload) => {
      state.isLoading = false;
      state.isError = false;
      state.deviceType = payload.payload;
    },
    setDevicesInitialState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoginLoading,
  setIsError,
  setDevices,
  setDevicesInitialState,
  setDevicesCustomerSelected,
  setDeviceType,
} = devicesSlice.actions;

export default devicesSlice.reducer;
