import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    isError: false,
    customField: [],
}

export const customFieldSlice = createSlice ({
    name: 'customFieldSlice',
    initialState,
    reducers: {
        startCustomFieldLoading:(state)=>{
            state.isLoading = true;
        },
        setIsError:(state, payload)=>{
            state.isLoading = false;
            state.isError = true;
            state.messageResult = payload.detail;
        },
        setCustomField:(state, payload)=>{
            state.customField = payload.payload;
            state.isLoading = false;
            state.isError = false;
        },
        setCustomFieldInitialState:(state)=>{
            state.isLoading = false;
            state.isError = false;
            state.customField = [];
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    startCustomFieldLoading,
    setIsError,
    setCustomField,
    setCustomFieldInitialState,
} = customFieldSlice.actions

export default customFieldSlice.reducer