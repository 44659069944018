import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  customerConfig: [],
}

export const customerConfigSlice = createSlice({
  name: 'customerConfigSlice',
  initialState,
  reducers: {
    startConfigLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
    setCustomerConfig:(state, payload)=>{
        state.customerConfig = payload.payload;
        state.isLoading = false;
        state.isError = false;
    },
    setCustomerConfigInitialState:(state)=>{
      state.isLoading = false;
      state.isError = false;
      state.customerConfig = [];
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
    startConfigLoading,
    setIsError,
    setCustomerConfig,
    setCustomerConfigInitialState,
} = customerConfigSlice.actions

export default customerConfigSlice.reducer