import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isError: false,
  messageResult:"",
  roles:{}
}

export const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    startRolesLoading:(state)=>{
        state.isLoading = true;
    },
    setIsError:(state, payload)=>{
        state.isLoading = false;
        state.isError = true;
        state.messageResult = payload.detail;
    },
   
    setRoleInfo:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.roles = payload.payload;
    },
    setRoleReset:(state)=>{
      state.roles={};
    }
  }
})

// Action creators are generated for each case reducer function
export const { startRolesLoading,setIsError,setRoleInfo,setRoleReset } = roleSlice.actions

export default roleSlice.reducer