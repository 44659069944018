import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topic: '',
  isLoading: false,
  isError: false,
  messageList: []
}

export const mqttClientSlice = createSlice({
  name: 'mqttClientSlice',
  initialState,
  reducers: { 
    setMessageList:(state, payload)=>{
        state.isLoading = false;
        state.isError = false;
        state.topic = payload.payload.topic;
        state.messageList= [...state.messageList, payload.payload.messagesList];
    },
    setMessageListEmpty:(state)=>{
        state.messageList=[];
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMessageList ,setMessageListEmpty } = mqttClientSlice.actions

export default mqttClientSlice.reducer